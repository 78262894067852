<div class="margin-top-3">
  <app-logo-with-subtitle></app-logo-with-subtitle>
</div>
<div class="margin-inline-auto width-fit margin-top-2 flex-x column-gap-1">
  <div class="width-4">
    <nb-form-field>
      <nb-icon nbPrefix icon="search-outline"></nb-icon>
      <input
        nbInput
        placeholder="Search by Gene, SNP or Locus"
        shape="semi-round"
        fullWidth
      />
    </nb-form-field>
  </div>
  <div>
    <nb-form-field>
      <nb-icon nbPrefix icon="paper-plane-outline"></nb-icon>
      <nb-select placeholder="Select Endpoint" shape="semi-round">
        <nb-option value="1">Endpoint 1</nb-option>
        <nb-option value="2">Endpoint 2</nb-option>
        <nb-option value="3">Endpoint 3</nb-option>
        <nb-option value="4">Endpoint 4</nb-option>
        <nb-option value="5">Endpoint 5</nb-option>
        <nb-option value="6">Endpoint 6</nb-option>
      </nb-select>
    </nb-form-field>
  </div>
</div>
<div class="margin-top-2 width-fit margin-inline-auto">
  <a [routerLink]="'/display/1'">Endpoint 1</a> |
  <a [routerLink]="'/display/2'">Endpoint 2</a> |
  <a [routerLink]="'/display/3'">Endpoint 3</a> |
  <a [routerLink]="'/display/4'">Endpoint 4</a> |
  <a [routerLink]="'/display/5'">Endpoint 5</a> |
  <a [routerLink]="'/display/6'">Endpoint 6</a>
</div>

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UserAuthService } from '../../services/user-auth.service';

@Component({
  selector: 'app-display-gene',
  templateUrl: './display-gene.component.html',
  styleUrl: './display-gene.component.scss',
})
export class DisplayGeneComponent implements OnInit {
  plotEndpoint: string = environment.baseUrl + 'manhattan/plot';
  plotSrc: SafeResourceUrl | undefined;
  @ViewChild('iframe') iframe: ElementRef | undefined;

  constructor(public auth: UserAuthService, public sanitize: DomSanitizer) {}

  ngOnInit() {
    this.plotSrc = this.sanitize.bypassSecurityTrustResourceUrl(
      this.plotEndpoint + '?token=' + this.auth.getToken()
    );
  }
}
